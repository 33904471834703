<template>
  <div class="indexpage publicpage u-flex u-col-top u-row-between">
    <div class="page-left">
      <Alarm></Alarm>
      <div class="public-panel panelh2 u-m-t-40">
        <h2>平台服务器负载</h2>
        <div class="u-flex loadbox u-m-t-20">
          <div class="item u-flex-1 u-flex u-flex-col">
            <div class="chartbox u-rela">
              <div id="pie2"></div>
              <div class="bg u-abso" :class="onPieBJ(load.cpu)"></div>
            </div>
            <h3 :class="onBj(load.cpu)"><span>cpu占用率</span></h3>
          </div>
          <div class="item u-flex-1 u-flex u-flex-col">
            <div class="chartbox u-rela">
              <div id="pie3"></div>
              <div class="bg u-abso" :class="onPieBJ(load.memory)"></div>
            </div>
            <h3 :class="onBj(load.memory)"><span>内存占用率</span></h3>
          </div>
          <div class="item u-flex-1 u-flex u-flex-col">
            <div class="chartbox u-rela">
              <div id="pie4"></div>
              <div class="bg u-abso" :class="onPieBJ(load.disk)"></div>
              <!-- <div class="dot3 u-abso"></div> -->
            </div>
            <h3 :class="onBj(load.disk)"><span>硬盘占用率</span></h3>
          </div>
        </div>
      </div>
      <div class="public-panel panelh3 u-m-t-40">
        <h2>站点列表</h2>
        <table class="table1">
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <tr>
            <th>站点名称</th>
            <th>实时负荷</th>
            <th>今日用电</th>
            <th>今日告警</th>
          </tr>
        </table>
        <div class="table-body">
          <vue-seamless-scroll :data="siteList" :class-option="defaultOption">
            <table class="table1">
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <tr v-for="(item, index) in siteList" :key="index">
                <td>{{ item.name }}</td>
                <td class="cyan">{{ item.pval }}</td>
                <td>{{ item.energyNum }}</td>
                <td class="yellow">{{ item.alarmNum }}</td>
              </tr>
            </table>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <div class="page-middle">
      <div class="mdtop u-rela">
        <Turntable></Turntable>
      </div>
      <!--      <div class="mdtop u-rela">
        <all-map></all-map>
      </div>-->
      <div class="public-panel panelh4 u-flex u-row-around">
        <div class="item u-flex" v-for="(item, index) in numList" :key="index">
          <div class="left u-flex u-row-center">
            <i
              :class="'icon' + index"
              class="animate__animated animate__pulse animate__infinite"
            ></i>
          </div>
          <div class="right u-flex u-flex-col u-row-center">
            <span class="u-font-26">{{ item.name }}</span>
            <span class="oswald u-font-40 blue">{{ item.num }}</span>
          </div>
        </div>
      </div>
      <div class="public-panel panelh5 u-m-t-40">
        <h2>今日负荷趋势</h2>
        <div class="chart" id="line1"></div>
        <!-- <div class="chart" id="bar1"></div> -->
      </div>
    </div>

    <div class="page-right">
      <div class="public-panel panelh1">
        <h2>测点统计</h2>
        <div class="chart" id="bar1"></div>
        <!-- <div class="chart" id="line1"></div> -->
      </div>
      <div class="public-panel panelh2 u-m-t-40">
        <h2>任务统计</h2>
        <div class="chart" id="radar1"></div>
      </div>
      <div class="public-panel panelh3 u-m-t-40">
        <h2>报警信息</h2>
        <table class="table1">
          <colgroup>
            <col width="25%" />
            <col width="15%" />
            <col width="30%" />
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <tr>
            <th>时间</th>
            <th>报警站点</th>
            <th>报警位置</th>
            <th>报警描述</th>
            <th>报警等级</th>
          </tr>
        </table>
        <div class="table-body">
          <vue-seamless-scroll :data="alarmList" :class-option="defaultOption">
            <table class="table1">
              <colgroup>
                <col width="25%" />
                <col width="15%" />
                <col width="30%" />
                <col width="15%" />
                <col width="15%" />
              </colgroup>
              <tr v-for="(item, index) in alarmList" :key="index">
                <td>{{ item.THETIME }}</td>
                <td :title="item.stationName" class="cyan">
                  {{ item.stationName }}
                </td>
                <td :title="item.OBJDESC">{{ item.OBJDESC }}</td>
                <td :title="item.DESCRIPTION">{{ item.DESCRIPTION }}</td>
                <td class="yellow" :class="onGrade(item.NAME)">
                  {{ item.NAME }}
                </td>
              </tr>
            </table>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <!-- 视频弹窗 -->
    <el-dialog
      class="custom-dialog index-dialog"
      :visible.sync="videoDialogVisible"
    >
      <template v-slot:title>
        <h3>视频</h3>
      </template>
      <div class="u-flex u-col-top">
        <div class="left u-flex-1 u-p-r-62">
          <div class="search">
            <el-input v-model="search" placeholder="请输入内容">
              <i slot="suffix" class="icon-search"></i>
            </el-input>
          </div>
          <el-tree
            class="u-m-t-26"
            :data="data"
            :props="defaultProps"
          ></el-tree>
        </div>
        <div class="videobox">
          <video src="../../assets/images/video.mp4" controls></video>
        </div>
      </div>
      <div class="off" @click="videoDialogVisible = false"></div>
    </el-dialog>
    <!-- 配电图弹窗 -->
    <el-dialog
      class="custom-dialog index-dialog"
      :visible.sync="distributionDialogVisible"
    >
      <template v-slot:title>
        <h3>配电图</h3>
      </template>
      <img class="img1" src="../../assets/images/dialog-img.png" alt="" />
      <div class="off" @click="distributionDialogVisible = false"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getHollowPie,
  getSingleDataPie,
  getLine,
  getRadar,
  getSeriesSingleColorBar,
} from "../../assets/js/charts";
import {
  getBigDataIndex,
  getBigDataServer,
  getBigDataMeasures,
  getBigDataMsgs,
  getBigDataAlarms,
  getBigDataAlarmnNum,
  getBigDataTasks,
  getBigDataLoad,
} from "@/api/screen";
import { color, hex2rgb } from "@/unit/common-params";
import vueSeamlessScroll from "vue-seamless-scroll";

import tools from "@/unit/autotips";
import Turntable from "@/views/index/components/Turntable";
import Alarm from "@/views/screen/Alarm";
export default {
  name: "",
  data() {
    return {
      project_id: 1, //项目id
      search: "", //搜索
      videoDialogVisible: false, //视频弹窗
      distributionDialogVisible: false, //配电图弹窗
      paramsColor: color,
      data: [
        {
          //树状结构
          label: "老厂房",
          children: [
            {
              label: "系统研发部",
              children: [
                {
                  label: "新仓房",
                },
                {
                  label: "长沙先导",
                },
              ],
            },
          ],
        },
      ],
      tool: {},
      defaultProps: {
        children: "children",
        label: "label",
      },

      load: {
        cpu: 0,
        memory: 0,
        disk: 0,
      },
      siteList: [
        {
          name: "老厂房",
          pval: "255.07kW",
          energyNum: "12.335kW",
          alarmNum: "0",
        },
      ],
      alarmList: [
        {
          THETIME: "2022-02-23 14:15:36",
          stationName: "新厂区",
          OBJDESC: "新厂房节点服务器通讯",
          DESCRIPTION: "通讯连通",
          NAME: "一般",
        },
      ],
      numList: [
        {
          name: "站点个数",
          num: 5,
        },
        {
          name: "设备总数",
          num: 154,
        },
        {
          name: "测点总数",
          num: 2653,
        },
        {
          name: "管理人员",
          num: 55,
        },
        {
          name: "运维工程师",
          num: 401,
        },
      ],
    };
  },
  components: {
    Alarm,
    Turntable,
    vueSeamlessScroll,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  beforeCreate() {
    document.title = this.$store.state.screen.title;
  },

  mounted() {
    setInterval(() => {
      this.getBigDataIndex();
      this.getBigDataServer();
      this.getBigDataMeasures();
      this.getBigDataMsgs();
      this.getBigDataLoad();
      this.getBigDataAlarms();
      this.getBigDataAlarmnNum();
      this.getBigDataTasks();
    }, 300000);
    setInterval(() => {
      window.location.reload();
    }, 3600000);
    this.getBigDataIndex();
    this.getBigDataServer();
    this.getBigDataMeasures();
    this.getBigDataMsgs();
    this.getBigDataLoad();
    this.getBigDataAlarms();
    this.getBigDataAlarmnNum();
    this.getBigDataTasks();
    //表格颜色
    this.onGrade();
  },
  methods: {
    getBigDataTasks() {
      getBigDataTasks({ project_id: this.project_id }).then((res) => {
        const data = [{ value: [] }];
        res.res.map((item) => {
          data[0].value.push(item.value);
        });
        console.log("task", data);
        this.$nextTick(() => {
          getRadar("radar1", data);
        });
      });
    },
    getBigDataAlarmnNum() {
      getBigDataAlarmnNum({ project_id: this.project_id }).then((res) => {
        let dataValue = res.res;
        this.$nextTick(() => {
          getHollowPie("pie1", dataValue);
        });
      });
    },
    getBigDataAlarms() {
      getBigDataAlarms({ project_id: this.project_id }).then((res) => {
        this.alarmList = res.res;
      });
    },
    getBigDataIndex() {
      getBigDataIndex({ project_id: this.project_id }).then((res) => {
        this.siteList = res.res;
      });
    },
    getDpr(num) {
      let width = window.screen.width;
      if (width > 1920) {
        return num;
      } else {
        return num / 2;
      }
    },
    getBigDataLoad() {
      getBigDataLoad({ project_id: this.project_id }).then((res) => {
        const title = res.res.titles;
        const data = res.res.datas;
        const series = [];
        data.map((item, index) => {
          series.push({
            name: title[index],
            type: "line",
            data: item,
            showSymbol: false,
            smooth: true,
            lineStyle: {
              color: this.paramsColor[index],
              width: this.getDpr(2),
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: this.paramsColor[index], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: hex2rgb(this.paramsColor[index], 0), // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },

            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: hex2rgb(this.paramsColor[index], 0.5), // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: this.paramsColor[index], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          });
        });
        this.$nextTick(() => {
          const { myChart, option } = getLine("line1", series);
          if (this.tool["line1"] !== undefined) {
            console.log("clearInterval", "消除");
            this.tool["line1"].clearLoop();
          }
          const tool = tools.loopShowTooltip(myChart, option, {
            loopSeries: true,
          });
          this.tool["line1"] = tool;
        });
      });
    },
    getBigDataMeasures() {
      getBigDataMeasures({ project_id: this.project_id }).then((res) => {
        this.$nextTick(() => {
          const { myChart, option } = getSeriesSingleColorBar(
            "bar1",
            res.res.map((item) => {
              return item.name;
            }),
            res.res.map((item) => {
              return item.meaNum;
            })
          );
          if (this.tool["bar1"] !== undefined) {
            this.tool["bar1"].clearLoop();
          }
          const tool = tools.loopShowTooltip(myChart, option, {
            loopSeries: true,
          });
          this.tool["bar1"] = tool;
        });
      });
    },
    getBigDataMsgs() {
      getBigDataMsgs({ project_id: this.project_id }).then((res) => {
        this.numList = res.res;
      });
    },
    getBigDataServer() {
      getBigDataServer().then((res) => {
        this.load.cpu = parseInt(res.res.cpu);
        this.load.memory = parseInt(res.res.memory);
        this.load.disk = parseInt(res.res.homes);
        this.$nextTick(() => {
          getSingleDataPie("pie2", this.load.cpu, this.getIfpie(this.load.cpu));
          getSingleDataPie(
            "pie3",
            this.load.memory,
            this.getIfpie(this.load.memory)
          );
          getSingleDataPie(
            "pie4",
            this.load.disk,
            this.getIfpie(this.load.disk)
          );
        });
      });
    },
    //饼图占有率控制颜色
    onBj(num) {
      if (num < 40) {
        return "bg1";
      } else if (num >= 40 && num < 60) {
        return "bg2";
      } else {
        return "bg1";
      }
    },
    //饼图背景色控制
    onPieBJ(num) {
      if (num < 40) {
        return "bgpie1";
      } else if (num >= 40 && num < 60) {
        return "bgpie2";
      } else {
        return "bgpie1";
      }
    },
    //饼图数值控制显示颜色
    getIfpie(num) {
      if (num < 40) {
        return ["rgba(0, 156, 255, 1)", "rgba(0, 156, 255, 0)"];
      } else if (num >= 40 && num < 60) {
        return ["rgba(244, 223, 105, 1)", "rgba(244, 223, 105, 0)"];
      } else {
        return ["rgba(0, 156, 255, 1)", "rgba(0, 156, 255, 0)"];
        //   return ["rgba(255, 51, 119, 1)", "rgba(255, 51, 119, 0)"];
      }
    },
    onGrade(name) {
      if (name == "可疑") {
        return "yellow1";
      } else if (name == "一般") {
        return "orange";
      } else if (name == "严重") {
        return "purple";
      } else if (name == "致命") {
        return "red";
      } else if (name == "操作") {
        return "violet";
      } else if (name == "正常") {
        return "orange1";
      }
    },
  },
};
</script>
