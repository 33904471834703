<template>
  <div>
    <div class="d3rotate u-abso">
      <div class="section3 u-abso">
        <div class="base u_p3d">
          <div class="ball_base u_p3d ball_1">
            <div class="ball">
              <div class="ball-item item1">
                <div class="top">
                  <span class="name">湿度</span>
                  <span class="value">{{ site.hum }}RH</span>
                </div>
                <span class="bottom">功能一</span>
              </div>
            </div>
          </div>
          <div class="ball_base u_p3d ball_2">
            <div class="ball">
              <div class="ball-item item2">
                <div class="top">
                  <span class="name">温度</span>
                  <span class="value">{{ site.temp }}℃</span>
                </div>
                <span class="bottom">功能二</span>
              </div>
            </div>
          </div>
          <div class="ball_base u_p3d ball_3">
            <div class="ball">
              <div class="ball-item item6-y">
                <div class="top">
                  <span class="name">有功</span>
                  <span class="value">{{ site.pval }}kW</span>
                </div>
                <span class="bottom">功能三</span>
              </div>
            </div>
          </div>
          <div class="ball_base u_p3d ball_4">
            <div class="ball">
              <div
                class="ball-item item4"
                @click="distributionDialogVisible = true"
              >
                <div class="top">
                  <span class="name">配电图</span>
                </div>
                <span class="bottom">功能四</span>
              </div>
            </div>
          </div>
          <div class="ball_base u_p3d ball_5">
            <div class="ball">
              <div class="ball-item item5" @click="videoDialogVisible = true">
                <div class="top">
                  <span class="name">视频</span>
                </div>
                <span class="bottom">功能五</span>
              </div>
            </div>
          </div>
          <div class="ball_base u_p3d ball_6">
            <div class="ball">
              <div class="ball-item item6">
                <div class="top">
                  <span class="name">无功功率</span>
                  <span class="value">{{ site.qval }}kvar</span>
                </div>
                <span class="bottom">功能六</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgt u-abso"></div>
    <div class="bgb u-abso"></div>
  </div>
</template>

<script>
import { getBigDataSingle } from "@/api/screen2";
export default {
  name: "Turntable",
  data() {
    return {
      time: "",
      server_id: this.$store.state.screen.server_id,
      interval: this.$store.state.screen.time,
      site: {
        energyNum: "",
        lval: "",
        pval: "",
        qval: "",
        temp: "",
        hum: "",
      },
    };
  },
  created() {
    this.getBigDataSingle();
    setInterval(() => {
      this.getBigDataSingle();
    }, this.interval);
  },
  methods: {
    getBigDataSingle() {
      getBigDataSingle({ server_id: this.server_id }).then((res) => {
        console.log(res);
        this.site = res.res;
      });
    },
  },
};
</script>

<style scoped></style>
